<template>
  <!--  todo: rewrite with component is-->
  <div>
    <auth-dialog
      v-if="store.authDialog.isVisible"
      v-model:visible="store.authDialog.isVisible"
      @inactive-wallet="onInactiveWallet"
      @wallet-bound="onWalletBound"
      @forgot-password="onForgotPassword"
      @verification-success="onVerificationSuccess"
      @email-confirm-required="onEmailConfirmForFormerUsersRequired"
    />

    <wallet-inactive-wallet-dialog
      v-if="isInactiveWalletDialogVisible"
      v-model:visible="isInactiveWalletDialogVisible"
    />

    <wallet-bound-successfully
      v-if="isWalletBoundSuccessfullyDialogVisible"
      v-model:visible="isWalletBoundSuccessfullyDialogVisible"
      :address="boundWalletAddress"
      :email="userEmail"
    />

    <suspense>
      <email-received-dialog
        v-if="isEmailReceivedDialogVisible"
        v-model:visible="isEmailReceivedDialogVisible"
        :show-hint="isEmailConfirmForFormerUsersRequired"
        :email="confirmEmail"
      >
        <template #submitButton>
          <de-button
            :variant="ButtonVariantOptions.confirm"
            :size="ButtonSizeOptions.medium"
            :label="$t('common.buttons.backToX', { field: 'common.buttons.logIn' })"
            class="tw-w-full tw-mt-10"
            @click="onBackClick"
          />
        </template>
      </email-received-dialog>

      <template #fallback>
        <de-progress-spinner />
      </template>
    </suspense>

    <change-password-dialog
      v-if="isChangePasswordFormVisible && route.query.hash"
      v-model:visible="isChangePasswordFormVisible"
      :hash="route.query.hash.toString()"
      @password-saved="onPasswordSaved"
    />

    <suspense>
      <password-saved-dialog
        v-if="isPasswordSavedVisible"
        v-model:visible="isPasswordSavedVisible"
        :title="passwordSaveddialogTitle"
      />

      <template #fallback>
        <de-progress-spinner />
      </template>
    </suspense>

    <user-complete-profile
      v-if="isUserCompleteProfileShown"
      v-model:visible="isUserCompleteProfileShown"
    />
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useAppStore } from '~/store/app';
import {
  ButtonSizeOptions,
  ButtonVariantOptions,
} from '~/shared/components/lib/button/button.entity';
import DeButton from '~/shared/components/lib/button/DeButton.vue';
import { AuthTab } from '~/data/domain/auth.entity';
import DeProgressSpinner from '~/shared/components/lib/progress/DeProgressSpinner.vue';

const AuthDialog = defineAsyncComponent(() => import('~/features/auth/AuthDialog.vue'));
const WalletInactiveWalletDialog = defineAsyncComponent(
  () => import('~/features/auth/wallet/WalletInactiveWalletDialog.vue'),
);
const WalletBoundSuccessfully = defineAsyncComponent(
  () => import('~/features/auth/wallet/WalletBoundSuccessfully.vue'),
);
const ChangePasswordDialog = defineAsyncComponent(
  () => import('~/features/auth/change-password/ChangePasswordDialog.vue'),
);
const UserCompleteProfile = defineAsyncComponent(
  () => import('~/features/user/components/UserCompleteProfile.vue'),
);
const EmailReceivedDialog = defineAsyncComponent(
  () => import('~/shared/components/dialogs/EmailReceivedDialog.vue'),
);
const PasswordSavedDialog = defineAsyncComponent(
  () => import('~/features/auth/change-password/PasswordSavedDialog.vue'),
);

const store = useAppStore();
const closeAuthDialog = () => {
  if (store.authDialog.isVisible) store.toggleAuthDialog(undefined, false);
};

const isInactiveWalletDialogVisible = ref(false);
const onInactiveWallet = () => {
  closeAuthDialog();
  isInactiveWalletDialogVisible.value = true;
};

const isWalletBoundSuccessfullyDialogVisible = ref(false);
const boundWalletAddress = ref();
const userEmail = ref();
const onWalletBound = (walletAddress: string, email: string) => {
  boundWalletAddress.value = walletAddress;
  userEmail.value = email;
  closeAuthDialog();
  isWalletBoundSuccessfullyDialogVisible.value = true;
};

const isEmailReceivedDialogVisible = ref(false);
const confirmEmail = ref('');
const isEmailConfirmForFormerUsersRequired = ref(false);

const onForgotPassword = (email: string) => {
  confirmEmail.value = email;
  isEmailReceivedDialogVisible.value = true;
  closeAuthDialog();
};
function onBackClick() {
  store.toggleAuthDialog(AuthTab.login, true);
  isEmailReceivedDialogVisible.value = false;
}

function onEmailConfirmForFormerUsersRequired(email: string) {
  isEmailConfirmForFormerUsersRequired.value = true;
  isEmailReceivedDialogVisible.value = true;
  confirmEmail.value = email;
}

const isChangePasswordFormVisible = ref(false);
const route = useRoute();

onMounted(() => {
  if (route.query.hash) {
    isChangePasswordFormVisible.value = true;
  }
});

const isPasswordSavedVisible = ref(false);
const onPasswordSaved = () => {
  isPasswordSavedVisible.value = true;
};

const isUserCompleteProfileShown = ref(false);
const onVerificationSuccess = () => {
  isUserCompleteProfileShown.value = true;
};

const passwordSaveddialogTitle = ref();
</script>
