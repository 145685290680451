<template>
  <div class="tw-min-h-screen tw-flex tw-flex-col">
    <main-header />

    <main class="tw-flex tw-flex-col tw-flex-grow">
      <slot />
    </main>

    <main-footer :class="footerClass" />
    <app-disclaimer :class="disclaimerClass" />

    <auth-flow />
    <header-submit-dialog
      v-if="store.submitDialog.isVisible"
      v-model:visible="store.submitDialog.isVisible"
    />
    <de-toast />

    <client-only>
      <transition name="de-connected-overlay">
        <app-cookies-notification v-if="store.isCookiesNotificationShown" />
      </transition>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useLazyAsyncData, useNuxtApp } from '#app';
import { useToast } from 'primevue/usetoast';
import enLocales from 'primelocale/en.json';
import ruLocales from 'primelocale/ru.json';
import MainHeader from '~/layouts/parts/MainHeader.vue';
import MainFooter from '~/layouts/parts/MainFooter.vue';
import AppDisclaimer from '~/layouts/parts/AppDisclaimer.vue';
import AuthFlow from '~/features/auth/AuthFlow.vue';
import DeToast from '~/shared/components/lib/toast/DeToast.vue';
import { useAppStore } from '~/store/app';
import { AuthService, type GoogleCallbackPayload } from '~/data/services/auth.service';
import { useLogin } from '~/shared/composable/useLogin';
import { toastErrorNotification } from '~/utils/toast-notification-helper';
import { useErrorHandling } from '~/shared/composable/useErrorHandling';
import { usePrimeVue } from '#imports';
// import { AppLocale } from '~/data/domain/app.locale';

const HeaderSubmitDialog = defineAsyncComponent(
  () => import('~/layouts/parts/header/HeaderSubmitDialog.vue'),
);
const AppCookiesNotification = defineAsyncComponent(
  () => import('~/layouts/parts/AppCookiesNotification.vue'),
);

defineProps<{
  footerClass?: string;
  disclaimerClass?: string;
}>();

const store = useAppStore();

const primevue = usePrimeVue();
function changePrimevueLocale() {
  switch (store.locale) {
    case 'en':
      primevue.config.locale = { ...enLocales.en };

      return;
    case 'ru':
      primevue.config.locale = { ...ruLocales.ru };

      return;
    default:
      primevue.config.locale = { ...enLocales.en };
  }
}

watch(() => store.locale, changePrimevueLocale, {
  immediate: true,
});

const { $customFetch } = useNuxtApp();
const authRepo = AuthService($customFetch);
const { makeUserLoggedIn } = useLogin();
const { getErrorMessage } = useErrorHandling();
const toast = useToast();
const route = useRoute();
const router = useRouter();

const googleLoginError = ref<Error | null>(null);

if (route.query.code) {
  const { data, error } = await useLazyAsyncData(() =>
    authRepo.googleCallback(route.query as unknown as GoogleCallbackPayload),
  );

  if (data.value) {
    await makeUserLoggedIn(data.value.access_token);
  }

  if (error.value) {
    googleLoginError.value = error.value;
  }

  router.replace(route.path);
}

async function showGoogleLoginError() {
  if (!googleLoginError.value) return;

  await nextTick();

  toastErrorNotification(toast, {
    body: getErrorMessage(googleLoginError.value.data?.response?.errorCode),
  });
}

onMounted(() => {
  showGoogleLoginError();
});
</script>
